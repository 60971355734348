<template>
  <v-dialog v-model="dialog" max-width="300">
    <template v-slot:activator="{ on }">
      <v-text-field
        outlined
        rounded
        :label="label"
        v-on="on"
        v-model="color"
        :rules="required"
      >
        <template v-slot:prepend-inner>
          <v-avatar size="30" class="mt-n1 mr-1" :color="color"></v-avatar>
        </template>
      </v-text-field>  
    </template>
    <v-card flat>
      <nice-header>{{label}}</nice-header>
      <v-color-picker 
        hide-canvas
        hide-sliders
        hide-inputs
        show-swatches
        v-model="color"
        swatches-max-height="400"
      ></v-color-picker>
    </v-card>
  
  </v-dialog>
</template>

<script>
export default {
  props: ['value', 'label'],
  data: () => ({
    dialog: false,
    required: [v => !!v || 'Please select a color']
  }),
  watch: {
    color () {
      this.dialog = false
    }
  },
  computed: {
    color: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
