<template>
  <admin-wrapper>
    <v-snackbar v-model="error" color="error">{{errorMessage}}</v-snackbar>
    <v-card flat tile>
      <v-container>
        <nice-header>Store appearance</nice-header>
        <v-card-text>
          
          <v-form v-model="valid">
            
            <v-row>
              <v-col cols="12"><v-subheader>Colours</v-subheader></v-col>  
              <v-col cols="12" md="6" class="py-0">
                <color-picker 
                  label="Primary colour"
                  v-model="form.primary"
                />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <color-picker 
                label="Secondary colour"
                v-model="form.secondary"
              />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <color-picker 
                  label="Accent colour"
                  v-model="form.accent"
                />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <color-picker 
                  label="Info colour"
                  v-model="form.info"
                />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <color-picker 
                  label="Warning colour"
                  v-model="form.warning"
                />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <color-picker 
                  label="Success colour"
                  v-model="form.success"
                />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <color-picker 
                  label="Error colour"
                  v-model="form.error"
                />
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-switch class="pl-3" v-model="form.darkMode" label="Dark mode">
                </v-switch>
              </v-col>
              <v-col cols="12"><v-subheader>Input and button styles</v-subheader></v-col> 
              <v-col cols="12" md="4" lg="2" class="py-0">
                <v-switch v-model="form.filled" label="Filled"></v-switch>
              </v-col>
              <v-col cols="12" md="4" lg="2" class="py-0">
                <v-switch v-model="form.dense" label="Dense"></v-switch>
              </v-col>
              <v-col cols="12" md="4" lg="2" class="py-0">
                <v-switch v-model="form.rounded" label="Rounded"></v-switch>
              </v-col>
              <v-col cols="12" md="4" lg="2" class="py-0">
                <v-switch v-model="form.outlined" label="Outlined"></v-switch>
              </v-col>
              <v-col cols="12" md="4" lg="2" class="py-0">
                <v-switch v-model="form.solo" label="Solo"></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            outlined
            color="success"
            :disabled="!valid"
            :loading="loading"
            @click="saveChanges"
          >
            Save changes
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </admin-wrapper>
</template>

<script>
import { stores, editTheme } from '@/graphql'
import { mapState } from 'vuex'
import ColorPicker from '@/components/ColorPicker.vue'
export default {
  apollo: { stores },
  components: { ColorPicker },
  data: () => ({
    valid: null,
    loading: false,
    error: false,
    errorMessage: null,
    form: {
      darkMode: false,
      primary: null,
      secondary: null,
      accent: null,
      info: null,
      warning: null,
      success: null,
      error: null,
      outlined: false,
      rounded: false,
      dense: false,
      solo: false,
      filled: false,
    }
  }),
  computed: {
    ...mapState(['selectedStore']),
    store () {
      if (this.stores) {
        return this.stores.find(store => store.hashId === this.selectedStore)
      }
      return null
    }
  },
  watch: {
    store (store) {
      if (store) {
        const { darkMode, primary, secondary, accent, info, warning, success, error, filled, rounded, outlined, dense, solo } = store
        this.form = { darkMode, primary, secondary, accent, info, warning, success, error, filled, rounded, outlined, dense, solo }
      }
    }
  },
  methods: {
    saveChanges () {
      this.loading = true
      this.$apollo.mutate({
        mutation: editTheme,
        variables: {
          store: this.selectedStore,
          ...this.form
        }
      }).catch((error) => {
        this.error = true
        this.errorMessage = error.message.replace('GraphQL error: ', '')
        this.loading = false
      }).then((result) => {
        if (result) this.loading = false
      })
    }
  }
}
</script>